import LogoN from "../../assets/svg/logo/nai/logo-nai-blanc.svg";

const Loading = () =>{
    return(
        <div className="loading-container">
            <img src={LogoN} alt="" />
        </div>
    )
}

export default Loading;