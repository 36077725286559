 const webProjectList = [
    {
        id : 1,
        title : "Bourse aux déchets : Adopte un Déchet",
        src : [
            "assets/img/screenshots/aud/aud-accueil.webp"
        ],
        alt : [
            "Page d'accueil du site adopte un déchet"
        ],
        lienPW : '',
        lienCS : ''
    },

    {
        id : 2,
        title : "Tutoriels screencast : Symfony-Online",
        src : [
            "assets/img/screenshots/so/symfony-online-accueil.webp"
        ],
        alt : [
            "Page d'accueil du projet symfony-online"
        ],
        lienPW : 'http://symfony-online.fr/',
        lienCS : ''
    },

    {
        id : 3,
        title : "Relation entre partenaires : En-recherche-active",
        src : [
            "assets/img/screenshots/era/era-accueil.webp"
        ],
        alt : [
            "Page d'accueil du projet en-recherche-active"
        ],
        lienPW : 'https://en-recherche-active.com/',
        lienCS : ''
    },

    {
        id : 4,
        title : "Site vitrine : APSEM",
        src : [
            "assets/img/screenshots/apsem/APSEM-Accueil.webp"
        ],
        alt : [
            "Page d'accueil du site de l'APSEM"
        ],
        lienPW : '',
        lienCS : ''
    },
]

export default webProjectList;