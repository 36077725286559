const portfolio3d = [
    {
        id : 1,
        nom : "My Little Litterature",
        class : "ui-mll",
        src : "https://prod.spline.design/rJ9yAIl46B4G3Acb/scene.splinecode",
        desc : "Mockup du projet d'entrainement My Little Litterature"
    },

    {
        id : 2,
        nom : "ETU'",
        class : "ui-etu",
        src : "https://prod.spline.design/R-DHVFmpakE9MPtw/scene.splinecode",
        desc : "Mockup du projet d'entrainement ETU'"
    },

];

export default portfolio3d;