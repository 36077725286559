const images = [
    {   
        id : "01",
        src : require("../assets/projets-web/aud-bg.webp"),
        logo : "assets/svg/logo/aud/adopte-un-dechet-logo-couleur.svg",
        text : "Adopte un déchet",
        desc : "La bourse aux déchets est une plateforme mettant en relation deux particuliers ou professionnels afin de récupérer ou donner leur déchets sans avoir besoin de les jeter.",
        tech : [
            '<li> <img src="assets/svg/icons/vuejs.svg" alt="Icône VueJS" />  </li>',
            '<li> <img src="assets/svg/icons/sass.svg" alt="Icône Sass/Scss" />  </li>',
            '<li> <img src="assets/svg/icons/adobe-illustrator.svg" alt="Icône Adobe Illustrator" /> </li>',
            '<li> <img src="assets/svg/icons/adobe-xd.svg" alt="Icône Adobe XD" /> </li>'
        ],
        date : 2021,
        scene : "https://prod.spline.design/DQ3IVktoR7mC0Djn/scene.splinecode"
    },

    {   
        id : "02",
        src : require("../assets/projets-web/so-fond.webp"),
        logo : "assets/svg/logo/so/logo-symfony-online-blanc.svg",
        text : "symfony online",
        desc : "Symfony-Online est un site web développé sous Symfony. L’objectif est de simplifier la plateforme php à travers des tutoriels et astuces sous le format vidéo screencast.",
        tech : [
            '<li> <img src="assets/svg/icons/symfony.svg" alt="Icône symfony" /> </li>',
            '<li> <img src="assets/svg/icons/sass.svg" alt="Icône Sass" />  </li>',
            '<li> <img src="assets/svg/icons/adobe-illustrator.svg" alt="Icône Adobe Illustrator" />  </li>',
            '<li> <img src="assets/svg/icons/adobe-xd.svg" alt="Icône Adobe XD" /> </li>',
            '<li> <img src="assets/svg/icons/adobe-premiere-pro.svg" alt="Icône Adobe Premiere Pro" /> </li>',
            '<li> <img src="assets/svg/icons/adobe-after-effects.svg" alt="Icône Adobe After Effects" /> </li>'
        ],
        date : 2021,
        scene : "https://prod.spline.design/dTCTxZHRDPEzBuqh/scene.splinecode"
    },

    {   
        id: "03",
        src : require("../assets/projets-web/era-fond.webp"),
        logo : "assets/svg/logo/era/logo.svg",
        text : "en recherche active",
        desc : "“En recherche active” est un site qui permet de soumettre facilement des profils de chercheurs d’emplois à la vue de recruteurs, et de les mettre en relation. Le projet est de faire la refonte du site qui a déjà été réalisé en 2021.",
        tech : [
            '<li> <img src="assets/svg/icons/vuejs.svg" /> </li>',
            '<li> <img src="assets/svg/icons/sass.svg" /> </li>',
            '<li> <img src="assets/svg/icons/adobe-illustrator.svg" /> </li>',
            '<li> <img class="figma" src="assets/svg/icons/figma.svg" /> </li>'
        ],
        date : 2022,
        scene : "https://prod.spline.design/GZ10VIFrsMS3rCJH/scene.splinecode"
    },

    {   
        id: "04",
        src : require("../assets/projets-web/apsem-fond.webp"),
        logo : "assets/svg/logo/apsem/apsem-logo.svg",
        text : "APSEM",
        desc : "L’APSEM (Association Socio-Educ Migrant) est une association formant vers un métier les personnes en difficultés. Le projet a pour but de mettre à jour la communication visuelle afin d’améliorer la visibilité de l’APSEM à en Occitanie.",
        tech : [
            '<li> <img src="assets/svg/icons/adobe-photoshop.svg" /> </li>',
            '<li> <img src="assets/svg/icons/adobe-illustrator.svg" /> </li>',
            '<li> <img class="figma" src="assets/svg/icons/figma.svg" /> </li>',
            '<li> <img src="assets/svg/icons/wordpress-simple.svg" /> </li>'
        ],
        date : 2022,
        scene : "https://prod.spline.design/ZuNxFmesenXWeszQ/scene.splinecode"
    }
]

export default images;