const portfolioVideo = [
    
    {
        id : 1,
        nom : "Motion Design",
        class : "p-so",
        webm : require("../assets/portfolio/video/so/symfony-online-teaser.webm"),
        gif : require("../assets/portfolio/video/so/symfony-online-apercu.gif"),
        desc : "Vidéo promotionnelle du projet Symfony-Online"
    },

    {
        id : 2,
        nom : "Tutoriel Screencast",
        class : "p-screencast",
        webm : require("../assets/portfolio/video/screencast/formation_screencast_bodymovin.webm"),
        gif : require("../assets/portfolio/video/screencast/nai-screencast-after-vers-web.gif"),
        desc : "Tutoriel screencast : mise en place d'une animation SVG"
    },

    {
        id : 3,
        nom : "Prise de vue",
        class : "p-courtMetrage",
        webm : require("../assets/portfolio/video/smoothie/court-metrage-les-innocents.webm"),
        gif : require("../assets/portfolio/video/smoothie/les-innocents.gif"),
        desc : "Court métrage les innocents"
    }
    
];

export default portfolioVideo;