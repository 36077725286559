const portfolioPrint = [
    {
        id : 1,
        nom : "Book Print",
        class : "p-book",
        src : require("../assets/portfolio/print/book/nai-book-open.webp"),
        desc : "Mockup d'un book ouvert"
    },

    {
        id : 2,
        nom : "Flyers",
        class : "p-flyer",
        src : require("../assets/portfolio/print/flyer/Flyer-Mockup-era.webp"),
        desc : "Mockup d'une brochure 3 volets print sur le projet en-recherche-active"
    },

    {
        id : 3,
        nom : "Carte de visite",
        class : "p-cdv",
        src : require("../assets/portfolio/print/cdv/cdv-nai.webp"),
        desc : "Exemple de carte de visite personnelle"
    }
]

export default portfolioPrint;